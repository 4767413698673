import React, { useState, useEffect } from 'react';
import { fetchData } from '../api';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, CircularProgress, Button, ButtonGroup } from '@mui/material';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const SalesData = () => {
  const [salesData, setSalesData] = useState([]);
  const [companies, setCompanies] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const companyResponse = await fetchData('companies');

        const companyMap = {};
        companyResponse.forEach(company => {
          companyMap[company.id] = company.name;
        });
        setCompanies(companyMap);
        console.log('Company map:', companyMap);

        try {
          const salesResponse = await fetchData('sales_data');
          
          const processedData = {};
          salesResponse.forEach(sale => {
            const { date, company_id, sales } = sale;
            if (!processedData[date]) {
              processedData[date] = { id: date, date };
            }
            processedData[date][company_id.toString()] = parseFloat(sales);
          });

          const formattedData = Object.values(processedData);
          console.log('Formatted data:', formattedData);
          setSalesData(formattedData);
        } catch (salesError) {
          console.error('Error fetching sales data:', salesError);
          setSalesData([]);
        }

      } catch (error) {
        console.error('Error fetching company data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);




  const columns = [
    { field: 'date', headerName: 'Date', width: 120 },
    ...Object.entries(companies).map(([id, name]) => ({
      field: id,
      headerName: name,
      width: 150,
      valueFormatter: (params) => {
        const value = params.value;
       
       return value
        //return value != null ? `${parseFloat(value).toFixed(2)}` : 'hi';
      },
    })),
  ];

  console.log('Columns:', columns);
  console.log('Sales data:', salesData);

  const exportToCSV = () => {
    const headers = ['Date', ...Object.values(companies)];
    const csvContent = [
      headers.join(','),
      ...salesData.map(row => [
        row.date,
        ...Object.keys(companies).map(id => row[id] != null ? row[id].toFixed(2) : 'N/A')
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'sales_data.csv');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const headers = ['Date', ...Object.values(companies)];
    const data = salesData.map(row => [
      row.date,
      ...Object.keys(companies).map(id => row[id] != null ? `$${row[id].toFixed(2)}` : 'N/A')
    ]);

    doc.autoTable({
      head: [headers],
      body: data,
    });

    doc.save('sales_data.pdf');
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ height: 600, width: '100%' }}>
      <h2>Sales Data</h2>
      <ButtonGroup style={{ marginBottom: '20px' }}>
        <Button onClick={exportToCSV}>Export to CSV</Button>
        <Button onClick={exportToPDF}>Export to PDF</Button>
      </ButtonGroup>
      <Paper style={{ height: 'calc(100% - 100px)', width: '100%' }}>
        <DataGrid
  rows={salesData}
  columns={columns}
  pageSize={10}
  rowsPerPageOptions={[10, 25, 50, 100]}
  disableSelectionOnClick
  getRowId={(row) => row.date}
/>
      </Paper>
    </div>
  );
};

export default SalesData;