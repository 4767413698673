// src/api.js
import { supabase } from './supabaseClient';

// Updated fetchData function using Supabase client
export const fetchData = async (endpoint) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    
    if (!session) {
      throw new Error('No valid session');
    }

    // Remove the slash handling since we're passing table names directly
    const table = endpoint;
    
    console.log(`Attempting to fetch data from table: ${table}`);
    
    const { data, error, status } = await supabase
      .from(table)
      .select('*');

    console.log(`Response status: ${status}`);
    console.log('Response data:', data);
    
    if (error) {
      console.error(`Supabase error details:`, error);
      throw error;
    }

    if (!data) {
      console.warn(`No data returned from ${table}`);
      return [];
    }

    return data;
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}:`, error);
    console.error('Error details:', {
      message: error.message,
      code: error.code,
      details: error.details,
      hint: error.hint
    });
    throw error;
  }
};

// Example of a more specific API call with auth
export const fetchUserProfile = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', userId)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

// Additional helper functions for common operations
export const createRecord = async (table, record) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();

    if (!session?.access_token) {
      throw new Error('No access token found');
    }

    const { data, error } = await supabase
      .from(table)
      .insert(record)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error(`Error creating record in ${table}:`, error);
    throw error;
  }
};

export const updateRecord = async (table, id, updates) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();

    if (!session?.access_token) {
      throw new Error('No access token found');
    }

    const { data, error } = await supabase
      .from(table)
      .update(updates)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error(`Error updating record in ${table}:`, error);
    throw error;
  }
};