import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import { fetchData } from '../api'; // Import the fetchData function

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const data = await fetchData('companies');
        setCompanies(data);
      } catch (error) {
        console.error('Error fetching companies:', error);
        setError('Failed to fetch companies. Please try again later.');
      }
    };

    fetchCompanies();
  }, []);

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>Companies</Typography>
      <List>
        {companies.map((company) => (
          <ListItem key={company.id}>
            <ListItemText primary={company.name} secondary={company.ticker} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default Companies;