import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Assuming you're using axios for API calls
import { useNavigate } from 'react-router-dom';

const StripePricingTable = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Load Stripe script
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.head.appendChild(script);

    // Fetch subscription status
    fetchSubscriptionStatus();

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const fetchSubscriptionStatus = async () => {
    try {
      const response = await axios.get('/subscription-status');
      setSubscriptionStatus(response.data.status);
      
      // Redirect to dashboard if subscription is active
      if (response.data.status === 'active') {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await axios.post('/cancel-subscription');
      alert('Subscription cancelled successfully');
      fetchSubscriptionStatus(); // Refresh status
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    }
  };

  // Function to handle successful subscription
  const handleSuccessfulSubscription = async () => {
    await fetchSubscriptionStatus();
    // If status is now active, redirect to dashboard
    if (subscriptionStatus === 'active') {
      navigate('/dashboard');
    }
  };

  return (
    <div>
      {subscriptionStatus && (
        <div>
          <p>Current Subscription Status: {subscriptionStatus}</p>
          {subscriptionStatus === 'active' && (
            <button onClick={handleCancelSubscription}>Cancel Subscription</button>
          )}
        </div>
      )}
      <stripe-pricing-table
        pricing-table-id="prctbl_1PtE9TDkesyiOSqn2V1F5n8a"
        publishable-key="pk_test_51Popv5DkesyiOSqnhPEbzsxAODfmq8hheBQ93cYRFx0LpqvRIXvESz4v8HbyFnm1jzkYy2DWYQeebJ0y9cRKFD6000K6rr5Bf1"
      ></stripe-pricing-table>
    </div>
  );
};

export default StripePricingTable;