import React from 'react';
import StripePricingTable from './SubscriptionPage';

function App() {
  return (
    <div className="App">
      <h1></h1>
      <StripePricingTable />
    </div>
  );
}

export default App;
